<template>
  <div>
    <!-- fixed top header -->
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <header class="page-header">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="12">
              <router-link to="/transfer" class="mr20">
                <el-button type="default"
                  ><i class="el-icon-back"></i
                ></el-button>
              </router-link>
              <h2 class="inline-block">{{ transferForm.transfer_id }}</h2>
              <el-tag type="warning ml20" effect="plain">{{
                transferForm.transfer_status
              }}</el-tag>
            </el-col>
          </el-row>
        </header>
        <el-steps :space="200"
        :active="activeStep"
        :finish-status="activeStep === 5 ? 'error' : 'success'"
        align-center
        style="justify-content: center" class="mb20">
            <el-step title="Үүсгэгдсэн"></el-step>
            <el-step title="Бэлтгэж байгаа"></el-step>
            <el-step title="Бэлтгэж дууссан"></el-step>
            <el-step title="Жолоочид хувиарлагдсан"></el-step>
            <el-step title="Цуцлагдсан" v-if="transferForm.supplier_status === 'supplier_cancel' || transferData.supplier_status === 'supplier_cancel'"></el-step>
          </el-steps>
        <!-- Transfer form -->
        <el-form
          label-position="top"
          :model="transferForm"
          ref="transferForm"
          v-loading="loading"
        >
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="text-center ">
                  <div>
                    <h1 class="mb5">Бүтээгдэхүүн</h1>
                  </div>
                </div>
                <div class="panel-item" style="padding-top:0">
                  <ul class="transfer-product-list">
                    <div
                      v-for="product in transferForm.products"
                      :key="product.productId"
                    >
                      <div v-if="product.variants.length > 0">
                        <ul>
                          <li
                            v-for="variant in product.variants"
                            :key="variant.variant_id"
                            class="transfer-product-list"
                          >
                            <el-row :gutter="20" type="flex" align="middle">
                              <el-col :span="5">
                                <div class="variantImage">
                                  <img :src="variant.variant_image" alt="" />
                                </div>
                              </el-col>
                              <el-col :span="16" :offset="2">
                                <div>
                                  {{ variant.variant_name }}
                                </div>
                                <div class="text-color-secondary">
                                  {{ variant.variant_sku }}
                                </div>
                              </el-col>
                              <el-col>
                                {{variant.get_price ? variant.get_price : '-'}}
                              </el-col>
                              <el-col :span="6">
                                <div class="text-center">
                                  <el-form-item>
                                    <el-input
                                      type="number"
                                      :disabled="true"
                                      min="0"
                                      v-model="variant.number_tobe_transferred"
                                    />
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <!-- <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <div>
                    <div>
                      <strong class="uppercase">{{
                        transferForm.warehouse_name
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="mt10">
                <el-row :gutter="30">
                  <el-col :span="6" class="text-right" style='bottom:10px'>
                    <el-button @click="generate" type="primary" icon="el-icon-printer"
                    :disabled="transferData.supplier_status === 'driver_in' ? false : true"
                    >Хэвлэх</el-button>
                  </el-col>
                  <el-col :span="8" style='bottom:10px'>
                    <el-button type="success" @click="centerDialogVisible = true" :disabled="isDisable" >Төлөв ахиулах</el-button>
                  </el-col>
                  <el-col :span="7" style='bottom:10px'>
                    <el-button @click="supplierCancelDialog = true"
                    :disabled="transferData.supplier_status === 'supplier_cancel' || transferForm.supplier_status === 'supplier_cancel' ? true : false"
                    type="info">
                    {{transferData.supplier_status === 'supplier_cancel' || transferForm.supplier_status === 'supplier_cancel' ? "Цуцлагдсан" : "Цуцлах"}}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div class="panel mt10">
                <div class="panel-item">
                  <header>Нийлүүлэгч байгууллага</header>
                  <div v-if="transferForm.supplier">
                    <div>
                      <strong class="uppercase">{{
                        transferForm.supplier.supplier_monName
                      }}</strong>
                    </div>
                    <p>
                      {{ transferForm.supplier.address }}
                    </p>
                    <p>{{ transferForm.supplier.phone }}</p>
                    <p>info@goyo.mn</p>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- <div>
          <el-col :span="6">
            <changes :changes="transferForm.changes.filter(el => el.type === 'log')"></changes>
          </el-col>
            </div> -->
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Цуцлах Dialog -->
    <el-dialog
      title="Цуцлах"
      :visible.sync="supplierCancelDialog"
      width="30%"
      center>
      <span>Та цуцлахдаа итгэлтэй байна уу?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="supplierCancelDialog = false">Болих</el-button>
        <el-button type="primary" @click="supplierCancel">Цуцлах</el-button>
      </span>
    </el-dialog>

    <!-- Төлөв ахиулах dialog -->
    <el-dialog
      title="Төлөв ахиулах"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <span>Та <strong>{{ beforeStatus }}</strong> төлөв ахиулах гэж байна</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Болих</el-button>
        <el-button type="success" @click="nextStep">Төлөв ахиулах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import { getUserName } from '../../../utils/auth.js'
// import changes from './components/changes.vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'editTransfer',
  components: {
    // changes
  },
  data () {
    return {
      transferData: {},
      supplierCancelDialog: false,
      centerDialogVisible: false,
      status: '',
      beforeStatus: '',
      afterStatus: '',
      totalCount: [],
      activeStep: 0,
      loading: true,
      transferForm: {
        products: [],
        warehouse_name: '',
        expected_arrival_at: null,
        supplier_id: null,
        created_at: null,
        transfer_id: null,
        transfer_name: '',
        changes: [],
        transfer_status: '',
        supplier: {},
        status: ''
      }
    }
  },
  created () {
    if (this.$route.params.transfer_id) {
      this.getTransfer(this.$route.params.transfer_id)
    }
  },
  computed: {
    isDisable () {
      var isDisabled = false
      if (this.activeStep === 4 || this.transferData.supplier_status === 'supplier_cancel' || this.activeStep === 5) {
        isDisabled = true
      } else { isDisabled = false }
      return isDisabled
    }
  },
  watch: {
    transferForm (data) {
      data.products.forEach(product => {
        product.variants.forEach(variant => {
          let totalPrice = 0
          totalPrice = Object.prototype.hasOwnProperty.call(variant, 'get_price') ? variant.get_price * (variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected) : 0 * (variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected)
          this.totalCount.push(totalPrice)
        })
      })
    }
  },
  methods: {
    calculateStep (status) {
      let returnStatus = 0
      switch (status) {
        case 'Үүсгэгдсэн':
          returnStatus = 1
          break
        case 'Бэлтгэж байгаа':
          returnStatus = 2
          break
        case 'Бэлтгэж дууссан':
          returnStatus = 3
          break
        case 'Жолоочид хувиарласан':
          returnStatus = 4
          break
        case 'Цуцалсан':
          returnStatus = 0
      }
      this.activeStep = returnStatus
      this.beforeStatus = status
    },
    async nextStep () {
      let status = ''
      this.centerDialogVisible = false
      switch (this.activeStep) {
        case 0:
          status = 'created'
          this.calculateStep('Үүсгэгдсэн')
          break
        case 1:
          status = 'supplier_processing'
          this.calculateStep('Бэлтгэж байгаа')
          break
        case 2:
          status = 'supplier_complete'
          this.calculateStep('Бэлтгэж дууссан')
          break
        case 3:
          status = 'driver_in'
          this.calculateStep('Жолоочид хувиарласан')
          break
        case 5:
          status = 'supplier_cancel'
          this.calculateStep('Цуцалсан')
      }
      const email = await getUserName()
      const query = this.transferForm.transfer_id + '?supplier_status=' + status + '&email=' + email
      await services.changeSupplierStatus(query).then(data => {
        this.transferData = data.data
      })
    },
    async supplierCancel () {
      this.status = 'supplier_cancel'
      const email = await getUserName()
      const query = this.transferForm.transfer_id + '?supplier_status=' + this.status + '&email=' + email
      services.changeSupplierStatus(query).then(data => {
        this.transferData = data.data
      })
      this.activeStep = 5
      this.supplierCancelDialog = false
    },
    generate () {
      var docDefinition = {
        content: [
          { style: 'header', text: 'Татан авалт: ' + this.transferForm.transfer_id, alignment: 'right' },
          { style: 'status', text: 'Татан авалтын төлөв: ' + this.transferForm.transfer_status },
          { style: 'head', text: this.transferForm.supplier.supplier_monName + ' барааны зарлагын баримт', alignment: 'center' },
          { text: 'Нийлүүлэгч байгууллага: ', style: 'subheader' },
          {
            type: 'none',
            ul: [
              { style: 'ss', text: 'Нэр: ' + this.transferForm.supplier.supplier_monName },
              { style: 'ss', text: 'Регистр: ' + this.transferForm.supplier.supplier_regno },
              { style: 'ss', text: 'Хаяг: ' + this.transferForm.supplier.address },
              { style: 'ss', text: 'НӨАТ төлөгч эсэх:............' },
              { style: 'ss', text: 'Утас: ' + this.transferForm.supplier.phone },
              { style: 'ss', text: 'Хүсэлт илгээсэн огноо: ' + this.transferForm.created_at },
              { style: 'ss', text: 'Харилцагч олгосон огноо: ....................................' },
              { style: 'ss', text: 'Харилцагчийн хөнгөлөлтийн хувь...............' }
            ]
          },
          ('\n'),
          {
            style: 'tableExample',
            table: {
              widths: [100, 70, 90, 50, 70, 80],
              body: [
                [{ text: 'Бүтээгдэхүүн', style: 'tableHeader' }, { text: 'Хувилбар', style: 'tableHeader' }, { text: 'SKU', style: 'tableHeader' }, { text: 'Тоо', style: 'tableHeader' }, { text: 'Үнэ', style: 'tableHeader' }, { text: 'Нийт үнэ', style: 'tableHeader' }]
                // [{ text: 'Нийт', style: 'tableHeader' }, { }, { }, { }, { }, { text: 'Нийт', style: 'tableHeader' }]
              ]
            }
          },
          { style: 'subheader', text: 'Тамга:' },
          ('\n'),
          ('\n'),
          ('\n'),
          ('\n'),
          { style: 'ss', text: 'Хүлээлгэн өгсөн: .........................................Утас: ......................................' },
          { style: 'ss', text: 'Хүлээн авсан: ..............................................Утас: ......................................' },
          { style: 'ss', text: 'Шалгасан нягтлан: ......................................Утас: ......................................' },
          ('\n'),
          { style: 'ss', text: 'Toktok XXK агуулахад хүлээн авсан огноо: ............................................' },
          ('\n'),
          ('\n'),
          { style: 'ss', text: 'Хэвлэсэн огноо: ' + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) }
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          title: {
            fontSize: 14,
            bold: true,
            margin: [-10, 0, 0, 10]
          },
          subheader: {
            fontSize: 10,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          head: {
            fontSize: 10,
            bold: true
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: 'black'
          },
          ss: {
            fontSize: 8
          },
          status: {
            fontSize: 10,
            bold: true,
            margin: [0, 0, 5, 15]
          }
        }
      }
      this.transferForm.products.forEach(product => {
        product.variants.forEach(variant => {
          docDefinition.content[6].table.body.push([{ style: 'ss', text: product.product_name }, { style: 'ss', text: variant.variant_name }, { style: 'ss', text: product.sku }, { style: 'ss', text: variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected }, { style: 'ss', text: (variant.get_price ? variant.get_price : '-') }, { style: 'ss', text: (Object.prototype.hasOwnProperty.call(variant, 'get_price') ? variant.get_price * (variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected) : 0 * (variant.number_tobe_transferred + variant.accepted + variant.canceled + variant.rejected)) }])
        })
      })
      docDefinition.content[6].table.body.push([{ style: 'tableHeader', text: 'Нийт' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { style: 'tableHeader', text: this.totalCount.reduce((add, a) => add + a, 0) }])
      pdfMake.createPdf(docDefinition).print()
    },
    async getTransfer (id) {
      await services.getOneTransfer(id).then(response => {
        if (response.status === 'success') {
          switch (response.data.supplier_status) {
            case 'supplier_cancel':
              this.activeStep = 5
              break
            case 'created':
              this.activeStep = 1
              break
            case 'supplier_processing':
              this.activeStep = 2
              break
            case 'supplier_complete':
              this.activeStep = 3
              break
            case 'driver_in':
              this.activeStep = 4
          }
          this.transferForm = response.data
          this.loading = false
        }
      })
    }
  }
}
</script>
<style>
.variantImage img {
  width: 100%;
  height: 100%;
}
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
